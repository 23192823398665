<template>
  <section class="xbts-smartholder">
    <div class="card w-100">
      <div class="card-body">
        <p class="x-crumbs">
          <router-link to="/">XBTS</router-link>
          <i class="mdi mdi-chevron-right"></i> <span>SmartHolder</span></p>
        <hr/>
        <h1 class="card-title text-white">{{ $t('smartholder.t1') }}</h1>
        <p>{{ $t('smartholder.description') }}</p>
      </div>
    </div>

    <div class="card w-100 mt-3">
      <div class="card-body">
        <table v-if="holders" class="table table-borderless">
          <tbody>
          <tr>
            <th>SmartHolders</th>
            <td>{{ holders.total.holders }}</td>
          </tr>
          <tr>
            <th>{{ $t('smartholder.total_in') }}</th>
            <td>{{ (formatNum(holders.total.realAmount * 1)) }} STH <span :alt="$t('smartholder.percent_total_emission')" :title="$t('smartholder.percent_total_emission')" class="small-x2">({{ ((holders.total.realAmount  / 249000000 ) * 100).toFixed(2) }}%)</span></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


    <div class="card w-100 mt-3">
      <div class="card-body">
        <b-tabs class="tab-minimal tab-minimal-success">
          <b-tab active>
            <template slot="title">
              <i class="icon-list"></i> {{ $t('smartholder.assets') }}
            </template>
            <table class="table table-striped table-borderless w-100 col-sm-auto">
              <thead>
              <tr>
                <th class="border-bottom-0" scope="col">{{ $t('assets.asset') }}</th>
                <th class="border-bottom-0 text-capitalize" scope="col">{{ $t('market.fees') }}</th>
                <th v-if="!$root.isMobile" class="border-bottom-0" scope="col">* 50%</th>
                <th v-if="!$root.isMobile" class="border-bottom-0" scope="col">** {{ $t('smartholder.qProgress') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr :key="'Pay'+ idxPay" class="pointer" v-for="(itemPay, idxPay) in orderedAssetsPay">
                <td class="text-left">
                  <router-link class="text-white" :to="'/asset/XBTSX.' + itemPay.ticker">
                    <img
                        :src="'images/assets/'+itemPay.ticker.toLowerCase()+'.png'"
                        class="asset-symbol mr-2"/>{{ itemPay.ticker }} ({{ itemPay.title }})
                  </router-link>
                </td>
                <td class="text-left">
                  {{ itemPay.accumulated_fees }}
                  <p v-if="$root.isMobile && itemPay.q" class="text-success mt-2">
                    <i class="icon-check"></i> {{ (itemPay.q).toFixed(0) }}%
                  </p>
                </td>
                <td v-if="!$root.isMobile" class="text-left">{{ (itemPay.accumulated_fees / 2).toFixed(8) }}
                </td>
                <td v-if="!$root.isMobile" class="text-left text-success">
                  <span v-if="itemPay.q"><i class="icon-check"></i> {{ (itemPay.q).toFixed(0) }}%</span>
                </td>
              </tr>
              <tr :key="idxNoq" @click="coinDetail(item.ticker)" class="pointer"
                  v-for="(item,idxNoq) in orderedAssetsNoQ">
                <td class="text-left">
                  <router-link :to="'/asset/XBTSX.' + item.ticker">
                    <img
                        :src="'images/assets/'+item.ticker.toLowerCase()+'.png'"
                        class="asset-symbol mr-2"/>{{ item.ticker }} ({{ item.title }})
                  </router-link>
                </td>
                <td class="text-left">
                  {{ (item.accumulated_fees * 1).toFixed(item.precision) }}
                  <p v-if="$root.isMobile" class="text-info mt-2"><i class="icon-clock"></i> {{ (item.q).toFixed(2) }}%
                  </p>
                </td>
                <td v-if="!$root.isMobile" class="text-left">{{ (item.accumulated_fees / 2).toFixed(item.precision) }}</td>
                <td v-if="!$root.isMobile" class="text-left">
                  <span class="text-info"><i class="icon-clock"></i> {{ (item.q).toFixed(3) }}%</span>
                </td>
              </tr>
              </tbody>
            </table>
            <p class="small mt-1">
              * {{ $t('smartholder.assets_1') }}
              <br/>** {{ $t('smartholder.assets_2') }}
            </p>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="icon-people"></i> SmartHolders
            </template>
            <table class="table table-borderless table-striped table-responsive-sm">
              <thead class="">
              <tr>
                <th class="border-bottom-0" scope="col">#</th>
                <th class="border-bottom-0" scope="col">Holder</th>
                <th class="border-bottom-0" scope="col">Stake STH</th>
                <th :title="$t('smartholder.bonus_info')" v-b-tooltip.html.top class="border-bottom-0" scope="col">+Bonus</th>
                <th class="border-bottom-0" scope="col">Bonus %</th>
                <th class="border-bottom-0" scope="col">Personal %</th>
                <th class="border-bottom-0" scope="col"><span :title="$t('smartholder.apye')" v-b-tooltip.html.top>APYE *</span>
                </th>
                <th class="border-bottom-0" scope="col">{{ $t('smartholder.months') }}</th>
                <th class="border-bottom-0" scope="col">{{ $t('smartholder.days_left') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr :key="idxHolder" v-for="(item, idxHolder) in holders.holders">
                <td>{{ idxHolder + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ formatNum(item.real_amount, '# ##0.00') }}</td>
                <td>{{ formatNum(item.bonus_amount) }}</td>
                <td>{{ item.bonus_percent }}%</td>
                <td>{{ item.person_percent }}%</td>
                <td>{{ (item.person_percent * item.months * 2).toFixed(2) }}%</td>
                <td>{{ item.months }}</td>
                <td>
                  <span v-if="(((item.days * 1) / (item.months * 30)) * 100) > 25" :class="(((item.days * 1) / (item.months * 30)) * 100) > 50 ? 'text-success' : 'text-info'">{{item.days}}</span>
                  <span v-else :class="(((item.days * 1) / (item.months * 30)) * 100) > 15 ? 'text-warning' : ''">{{item.days}}</span>

                  <!--<span class="small-x2 ml-1">{{(((item.days * 1) / (item.months * 30)) * 100).toFixed(2)}}%</span>-->

                </td>
              </tr>
              </tbody>
            </table>
            <p class="small mt-1">
              * {{$t('smartholder.bonus_info')}}
              <br/>* APYE - {{$t('smartholder.apye')}}
            </p>


          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="icon-book-open"></i> {{ $t('smartholder.payouts') }}
            </template>
            <div class="row">
              <div :key="idxReports" class="mt-2 mb-3 col-md-6" v-for="(item,idxReports) in reports">
                <div class="container">
                  <div class="col">
                    <div class="overflow-hidden mb-1 border border-dark">
                      <div class="card-header">
                        <h4 class="m-0 text-warning text-center">#{{ item.id }}
                          {{ item.date.substr(0, 10) }}</h4>
                      </div>
                      <div class="">
                        <table class="table mb-0 table-borderless table-striped">
                          <tbody>
                          <tr :key="idxReport" v-for="(report, idxReport) in item.data">
                            <td class="text-right">{{ report.ticker }} <img
                                :src="'images/assets/'+report.ticker.toLowerCase()+'.png'"
                                class="asset-symbol ml-3"/></td>
                            <td class="text-left">{{ report.total }}</td>
                            <!--<td v-if="report[curr]">{{currSymbols[curr]}} {{report[curr]}}</td>-->
                          </tr>
                          <!--
                          <tr v-if="item.data[0][curr]">
                            <td></td>
                            <td class="text-right"></td>
                            <td>{{currSymbols[curr]}} {{total(item.data)}}</td>
                          </tr>
                          -->
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="icon-briefcase"></i> {{ $t('smartholder.my') }}
            </template>
            <p v-show="!this.$root.pin">
              <router-link class="btn btn-info text-uppercase" to="/auth-pages/login"><i
                  class="icon-login ml-2 mr-2 icon-16"></i> {{ $t('app.login') }}
              </router-link>
            </p>

            <MyStakes v-if="this.$root.pin"/>
          </b-tab>

        <b-tab v-if="this.$root.pin && profit">
          <template slot="title">
            <i class="icon-briefcase"></i> {{ $t('smartholder.received_payments') }}
          </template>

          <h4 class="text-white text-center">{{ $t('smartholder.title_total_assets') }}</h4>


              <table class="table mb-0 table-borderless table-striped">
                <tbody>
                <tr :key="idxProfit" v-for="(item, idxProfit) in profit">
                  <td class="text-right">{{ idxProfit }} <img
                      :src="'images/assets/'+idxProfit.toLowerCase()+'.png'"
                      class="asset-symbol ml-3"/></td>
                  <td class="text-left">{{ item }}</td>
                </tr>
                </tbody>
              </table>



        </b-tab>

        </b-tabs>
      </div>
    </div>
  </section>
</template>

<script>
import {currSymbols} from '@/config.js';

export default {
  name: "SmartHolder",
  components: {
    MyStakes: () => import('@/components/smartholder/MyStakes')
  },
  data() {
    return {
      currSymbols: currSymbols,
      currency: "usd",
      daysColor: 'success',
      breadcrumbs: [
        {
          text: "XBTS",
          href: "/#/"
        },
        {
          text: "SmartHolder",
          active: true
        },
      ],
      orderedObj: {}
    }
  },
  computed: {
    curr() {
      return this.$store.getters['paprika/currency'] || 'usd';
    },
    assetsNoq() {
      return this.$store.getters['smartholder/assetsNoq']
    },
    assetsPay() {
      return this.$store.getters['smartholder/assetsPay']
    },
    holders() {
      return this.$store.getters['smartholder/holders']
    },
    reports() {
      return this.$store.getters['smartholder/reports']
    },
    profit() {
      return this.$store.getters['smartholder/profit']
    },
    orderedAssetsNoQ: function () {
      if (this.assetsNoq) {
        return _.orderBy(this.assetsNoq, 'q', 'desc')
      } else {
        return []
      }

    },
    orderedAssetsPay: function () {
      if (this.assetsPay) {
        return _.orderBy(this.assetsPay, 'q', 'desc')
      } else {
        return []
      }

    }
  },
  methods: {
    total(items) {
      let sum = 0;
      if (items[0].usd) {
        for (let i=0; i < items.length; i++) {
          sum = sum + items[i][this.curr] * 1;
        }
      }
      return sum;
    },
  },
  async created() {
    const latestReportCountRemote = await this.$store.dispatch('smartholder/latestReportCountRemote');
    const latestReport = this.$store.getters['smartholder/latestReport'];
    if (latestReport < latestReportCountRemote) {
      await this.$store.dispatch('smartholder/fetchLatestReports');
    }

    if (this.$root.pin) {
      //console.log(this.$root.account.account.name)
      await this.$store.dispatch('smartholder/getMyProfit', this.$root.account.account.name);
      //await this.$store.dispatch('smartholder/getMyProfit', 'euro888');
    }
  }
}
</script>

<style scoped>
.asset-symbol {
  width: 18px;
  height: 18px;
}

.vue-slide-bar-component {
  min-height: auto !important;
}
</style>
